
import React from "react";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { CustomerContext } from "../../common/CustomerContext.js";
import { withTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import {getCountryNameWithCode} from '../../common/helpers/CountryCodeHelper.js';
import {palette} from '../../assets/Palette.jsx';
import Grid from '@material-ui/core/Grid';
import { isGtinValid, isGlnValid, isEkgValid } from '../../common/helpers/Validator.js'
import{ getCurrentLanguage, getCookie } from '../../common/helpers/Common.js';
import { ekgCodeList } from '../../common/data/ekg_code_list';
import { countryCodes } from '../../common/data/country_code_list';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RefreshIcon from '@material-ui/icons/Refresh';

class SearchFilter extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            targetMarketSearch: this.props.targetMarketSearch,
            gtinSearch: this.props.gtinSearch,
            glnSearch: this.props.glnSearch,
            ekgSearch: this.props.ekgSearch,
            validTargetMarkets: this.props.validTargetMarkets,
            isSearching: this.props.isSearching,
            countryEkgCodeList: [],
        }

        this.handleEkgChange = this.handleEkgChange.bind(this);
        this.handleTargetMarketChange = this.handleTargetMarketChange.bind(this);
    }

    componentDidMount() {
        this.getValidTargetMarkets();

        let countryCode = getCookie('country_code')
        if ( countryCode !== "" ) {
            this.setState( { countryEkgCodeList: ekgCodeList[countryCode] })
        }
    }

    getValidTargetMarkets() {
        this.context.apiService.getValidTargetMarketsForMarkant()
            .then(res => {
                if (res.ok) {
                    res.json().then(res => {
                        this.setState({ validTargetMarkets: res.data });
                    });
                } else {
                    res.json().then(res => {
                        console.log(res);
                    })
                }
            });
    }

    shouldSubmitButtonBeEnabled() {
        return (isGtinValid(this.state.gtinSearch)
            && isGlnValid(this.state.glnSearch)
            && isEkgValid(this.state.ekgSearch))
    }

    handleTargetMarketChange(event){
        const targetMarket = event.target.value
        if (targetMarket == 'all') {
            this.props.handleEkgSearch('')
            this.props.handleGtinSearch('')
            this.props.handleGlnSearch('')
            this.setState({countryEkgCodeList: []})
            this.props.handleTargetMarketSearch(targetMarket)
        } else {
            let countryCode = countryCodes[targetMarket]
            this.setState({
                countryEkgCodeList: ekgCodeList[countryCode]
            }, () => this.props.handleTargetMarketSearch(targetMarket))
        }
    }

    handleEkgChange (event, newValue) {
        this.setState({
            ekgSearch: newValue
        }, () => this.props.handleEkgSearch(newValue))
    }

    render() {
        const { t } = this.props;
        const language = getCurrentLanguage(process.env.REACT_APP_SOURCE)

        return <Grid container direction="row">
                    <Grid item xs="2">
                        <TextField
                            label={t('markantDataRequestModal.targetMarket')}
                            value={this.props.targetMarketSearch}
                            onChange={this.handleTargetMarketChange}
                            select variant="outlined" size="small"
                            style={{ width: '90%', borderColor: palette.secondary.main}}
                            disabled={this.props.isSearching}
                        >
                            {this.props.validTargetMarkets.map(tm => <MenuItem key={tm} value={tm}>{getCountryNameWithCode(tm, language)}</MenuItem>)}
                        </TextField>
                    </Grid>
                    <Grid item xs="2">
                        <Autocomplete
                            style={{width: "90%"}}
                            id="markant-ekg-code-list"
                            size="small"
                            value={this.props.ekgSearch}
                            onChange={this.handleEkgChange}
                            options={this.state.countryEkgCodeList}
                            renderInput={(params) => (
                                <TextField {...params} label={t('markantDataRequestModal.ekg')} variant="outlined" />
                            )}
                        />
                    </Grid>
                    <Grid item xs="2">
                        <TextField id="gtinSearch"
                            style={{width: "90%"}}
                            label={t('markantDataRequestModal.gtin')}
                            value={this.props.gtinSearch}
                            error={!isGtinValid(this.props.gtinSearch)}
                            helperText={isGtinValid(this.props.gtinSearch) ? '' : t('gdsnSubscriptionModal.gtinValidationText')}
                            onChange={this.props.handleOnChange} variant="outlined" size="small"
                            inputProps={{ maxLength: 14 }}
                            disabled={this.props.isSearching}
                        />
                    </Grid>
                    <Grid item xs="2">
                        <TextField id="glnSearch"
                            style={{width: "90%"}}
                            label={t('markantDataRequestModal.gln')}
                            value={this.props.glnSearch}
                            error={!isEkgValid(this.props.glnSearch)}
                            helperText={isEkgValid(this.props.glnSearch) ? '' : t('gdsnSubscriptionModal.gpcValidationText')}
                            onChange={this.props.handleOnChange} variant="outlined" size="small"
                            disabled={this.props.isSearching}
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained"
                            color="primary"
                            disabled={!this.shouldSubmitButtonBeEnabled() ||this.props.isSearching}
                            onClick={this.props.submitFunction}
                            style={{ height: '40px' }}
                        >
                            {t('gdsnSubscriptionModal.search')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Tooltip title={t('markantDataRequestModal.refresh')} aria-label={t('markantDataRequestModal.refresh')}>
                            <IconButton onClick={this.props.submitFunction} style={{marginTop: -5, marginLeft: 5}}>
                                <RefreshIcon color="primary" style={{cursor: "pointer"}}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
    }
}
  
export default withTranslation()(SearchFilter);