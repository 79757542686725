import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Modal from 'react-modal';
import Checkbox from '@material-ui/core/Checkbox';
import { withTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import {palette} from '../../assets/Palette';
import React, { useState, useEffect } from 'react';
import { Table } from "reactstrap";
import Loader from 'react-loader-spinner';
import { CustomerContext } from "../../common/CustomerContext.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { translateKey } from '../../common/helpers/Common';

const tradeItemHierarchyModalStyle = {
    content: {
        top: '25%',
        left: '50%',
        right: '30%',
        marginRight: '-50%',
        transform: 'translate(-50%, -20%)',
        overlay: { zIndex: 1000 }
    }
};

function Folder({ item, indent, addToSelection, removeFromSelection, isHierarchyOpen, searchItem}) {
    const [isOpen, setIsOpen] = useState(isHierarchyOpen);
    const [isChecked, setIsChecked] = useState(false);
    const prefix = 'tradeItemHierarchyModal'
    
    useEffect(() => {
        setIsOpen(isHierarchyOpen);
      }, [isHierarchyOpen]);
  
    const toggleFolder = () => {
        setIsOpen(!isOpen);
    };

    const checkItem = () => {
        if(isChecked)removeFromSelection(item.id);
        else addToSelection(item.id);
        setIsChecked(!isChecked)
    }

    if (item.parentTradeItem.length === 0) indent++
    let margin = `${indent * 20}px`
    return (
        <>
          <tr style={{ cursor: 'pointer', fontWeight: 'bold', width: '40%'} }>
            <td key={item.gtin} >
                <div style={{display: 'flex', alignItems: 'center', marginLeft: margin, marginRight: '20px', color: palette.text.main, fontSize: 'large', width: '40%'}}>
                    {item.parentTradeItem.length > 0 && (isOpen ? (<FontAwesomeIcon icon={faFolderOpen} onClick={toggleFolder}/>) : (<FontAwesomeIcon icon={faFolder} onClick={toggleFolder} />))}
                    <div><Checkbox color="primary" checked={isChecked} onClick={checkItem} inputProps={{ 'aria-label': 'secondary checkbox' }} /></div>
                    <div onClick={ () => searchItem(item.gtin, item.gln, item.targetMarket)}>{item.gtin}</div>
                </div>
            </td>
            <td key={item.gtin+'-tradeitemUnitdescriptorCode'} >
                <div style={{ color: palette.text.main,alignItems: 'center', fontSize: 'large', width: '40%', marginRight: '5px' }}>{item.tradeItemUnitDescriptorCode}</div>
            </td>
            <td key={item.gtin+'-hasNonGtinLogisticsInformation'} >
                <div style={{ color: palette.text.main,alignItems: 'center', fontSize: 'large', width: '40%', marginRight: '5px' }}>{
                item.hasNonGtinLogisticsInformation ? translateKey('hasNonGtinLogisticsInformationTrue', prefix) : translateKey('hasNonGtinLogisticsInformationFalse', prefix)}</div>
            </td>
            <td key={item.gtin+'-quantityOfTradeItem'} >
                <div style={{ color: palette.text.main,alignItems: 'center', fontSize: 'large', width: '40%', marginRight: '5px' }}>{item.quantityOfTradeItem}</div>
            </td>
          </tr>

        {isOpen && item.parentTradeItem.length > 0 &&
            item.parentTradeItem.map(parentItem => (
                <Folder key={parentItem.gtin} item={parentItem} addToSelection={addToSelection} removeFromSelection={removeFromSelection} isHierarchyOpen={isHierarchyOpen} indent={indent+1} searchItem={searchItem}/>
                ))
        }
        </>
    )
}

class TradeItemHierarchyModal extends React.Component {

    static contextType = CustomerContext;
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false,
            selectedItems: [],
            hierarchyData: [],
            hierarchyTree: null,
            isHierarchyLoadingComplete: false,
            isHierarchyOpen: true
        }
        this.toggleIsChecked = this.toggleIsChecked.bind(this);
        this.addToSelection = this.addToSelection.bind(this);
        this.removeFromSelection = this.removeFromSelection.bind(this);
        this.getHierarchyData = this.getHierarchyData.bind(this);
        this.toggleHierarchy = this.toggleHierarchy.bind(this);
        this.searchItem = this.searchItem.bind(this);
    }

    componentDidMount() {        
        this.getHierarchyData(this.props.product);

    }

    addToSelection(item){
        if(this.state.selectedItems.includes(item)) return;
        this.setState( (prevState) => ({
        selectedItems: [...prevState.selectedItems, item]
        })
       )
    }

    removeFromSelection(item) {
        if(!this.state.selectedItems.includes(item)) return;
        this.setState(prevState => ({
                selectedItems: prevState.selectedItems.filter(i => i !== item)
            }));
    }

    toggleIsChecked(){
        this.setState({
            isChecked: !this.state.isChecked
        })
    }

    getHierarchyData(product) {
        if(product){
            this.setState({ isHierarchyLoadingComplete: false })
            this.context.apiService.getGdsnTradeItemHierarchy(product['gtin'], product['informationProviderOfTradeItem']['gln'], product['targetMarket']['targetMarketCountryCode'])
            .then(res => res.json())
            .then(res => {
                this.setState({ hierarchyData: res, 
                    hierarchyTree: this.reverseHierarchyFromItem(res, this.props.product['gtin']),
                    isHierarchyLoadingComplete: true })
            });
        }
      }

    reverseHierarchyFromItem(hierarchyData, gtin){
        let treeStructure = {}
        for (let i in hierarchyData){
            if(hierarchyData[i].tradeItemUnitDescriptorCode !== 'BASE_UNIT_OR_EACH'){
                gtin = this.getBaseItem(hierarchyData[i])
            }
            if(gtin){
                this.reverseHierarchyFromItemRec(hierarchyData[i], hierarchyData[i], gtin, null, treeStructure)
            }
        }
        return treeStructure
    }

    reverseHierarchyFromItemRec(rootHierarchyData, currentHierarchyData, targetGtin, parentGtin, treeStructure){
        if(!targetGtin) return null;

        if (currentHierarchyData['gtin'] === targetGtin){
            if(parentGtin){
                let parentObj = this.getObjFromTreeByGtin(rootHierarchyData, parentGtin, 'childTradeItem') // search obj in children for rootHierarchyData

                if(!parentObj.hasOwnProperty('parentTradeItem')){
                    parentObj['parentTradeItem'] = [] 
                }
                let targetObj = this.getObjFromTreeByGtin(treeStructure, targetGtin, 'parentTradeItem') // find current object in structure

                if(targetObj){
                    if(targetObj['parentTradeItem'].every( (obj) => parentObj['gtin'] !== obj['gtin'])){
                        targetObj['parentTradeItem'].push(parentObj)
                    } 
                } else {
                    if(Object.keys(treeStructure).length === 0){
                        targetObj = this.getObjFromTreeByGtin(rootHierarchyData, targetGtin, 'childTradeItem')
                        targetObj['parentTradeItem'] = [] 
                        targetObj['parentTradeItem'].push(parentObj)
                        Object.assign(treeStructure, targetObj)
                    }
                }
                this.reverseHierarchyFromItemRec(rootHierarchyData, rootHierarchyData, parentGtin, null, treeStructure) // walk up the tree
            } else {
                if(Object.keys(treeStructure).length === 0){ // base case
                    let targetObj = this.getObjFromTreeByGtin(rootHierarchyData, targetGtin, 'childTradeItem')
                    targetObj['parentTradeItem'] = [] 
                    Object.assign(treeStructure, targetObj)
                }
            }
        } else {
            if(currentHierarchyData['childTradeItem'].length > 0){
                parentGtin = currentHierarchyData['gtin']
                for(let i in currentHierarchyData['childTradeItem']){ // walk down the tree
                    this.reverseHierarchyFromItemRec(rootHierarchyData, currentHierarchyData['childTradeItem'][i], targetGtin, parentGtin, treeStructure)
                }
            }
        }

    }

    getBaseItem(hierarchyData){
        if(hierarchyData.tradeItemUnitDescriptorCode === "BASE_UNIT_OR_EACH"){
            return hierarchyData.gtin;
        } else if (hierarchyData.childTradeItem.length > 0){
            return this.getBaseItem(hierarchyData.childTradeItem[0]);
        } else {
            return null;
        }
    }

    getObjFromTreeByGtin(tree, gtin, searchKey){
        if (typeof tree !== 'object' || Object.keys(tree).length === 0) {
            return null;
        }
    
        if (tree['gtin'] === gtin) {
            return tree;
        }
    
        if(tree.hasOwnProperty(searchKey) && tree[searchKey].length > 0){
            for(let i in tree[searchKey]){
                const result = this.getObjFromTreeByGtin(tree[searchKey][i], gtin, searchKey)
                if(result) return result
            }
        }
        return null
    }

    toggleHierarchy(){
        this.setState({
            isHierarchyOpen: !this.state.isHierarchyOpen
        });
    }

    searchItem(gtin, gln, targetMarket){
        this.props.callSearchAPI(gtin, '250', '0', 'AND', [{'GDSN': true}], false, false, false, [{[targetMarket]: true}], false, 'base', 'gtin')
        this.props.toggleTradeItemHierarchyModal()
        }

    render() {
        const prefix = "tradeItemHierarchyModal"
        return <Modal
            isOpen={this.props.tradeItemHierarchyModal}
            toggle={this.props.toggleTradeItemHierarchyModal}
            ariaHideApp={false}
            tabIndex={-1}
            style={tradeItemHierarchyModalStyle}
        >
            <div className='TradeItemHierarchyModal-container'>
                <div className='TradeItemHierarchyModal-header'>
                </div>
                <p/>
                <div className='TradeItemHierarchyModal-content'>
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', right: '4%', top: '5%' }}>
                    <Tooltip title={'searchResults.close'}>
                        <IconButton
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.props.toggleTradeItemHierarchyModal}
                            style={{ outline: 'none' }}
                        >
                        <CloseRoundedIcon style={{ color: palette.warning.close }} />
                        </IconButton>
                    </Tooltip>
                    </div>
                </div>
                <h2 key={'h1'}>{translateKey("tradeItemHierarchy", prefix) + " " + this.props.product.gtin}</h2>
                <p/>
                {
                !this.state.isHierarchyLoadingComplete ?  <Loader type="TailSpin" color={palette.secondary.light} height={100} width={100} /> :
                this.state.hierarchyData.length > 0 ?
                <div>
                    <div style={{display: 'flex', alignItems: 'left', marginRight: '20px', color: palette.secondary.main, fontSize: 'large', width: '40%'}}>
                    <Tooltip title={translateKey('toggleHierarchy', prefix)}>
                        {this.state.isHierarchyOpen ? <FontAwesomeIcon icon={faFolderOpen} onClick={this.toggleHierarchy}/> : 
                        <FontAwesomeIcon icon={faFolder} onClick={this.toggleHierarchy} />}
                    </Tooltip>
                    </div>
                    <Table>
                        <thead fontSize='large' fontWeight='bold'>
                        <tr >       
                            <th style={{ color: palette.text.main, fontSize: 'large', fontWeight: 'bold'} }>GTIN</th>
                            <th style={{ color: palette.text.main, fontSize: 'large', fontWeight: 'bold'} }>{translateKey("tradeItemUnitDescriptorCode", prefix)}</th>
                            <th style={{ color: palette.text.main, fontSize: 'large', fontWeight: 'bold'} }>{translateKey("nonGtinLogisticsInformation", prefix)}</th>
                            <th style={{ color: palette.text.main, fontSize: 'large', fontWeight: 'bold'} }>{translateKey("quantityOfChildTradeItem", prefix)}</th>
                        </tr>
                        </thead>
                        <tbody>
                            <Folder key={this.state.hierarchyTree.gtin} rootItem={this.props.product} item={this.state.hierarchyTree} addToSelection={this.addToSelection} removeFromSelection={this.removeFromSelection} isHierarchyOpen={this.state.isHierarchyOpen} indent={0} searchItem={this.searchItem}/>
                        </tbody>
                    </Table>
                </div> : <div>{translateKey("noHierarchyDataFound",prefix)}</div>
                }
            <div className='TradeItemHierarchyModal-footer'>
            </div>
        </div>
        </Modal>;
    }

}

export default withTranslation()(TradeItemHierarchyModal);