import React from "react";
import Moment from 'react-moment';
import { saveAs } from "file-saver";
import Loader from 'react-loader-spinner';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import {palette} from '../../assets/Palette';
import MuiAlert from '@material-ui/lab/Alert';
import { red } from '@material-ui/core/colors';
import { withTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
import{getCurrentLanguage} from '../../common/helpers/Common.js';
import { CustomerContext } from "../../common/CustomerContext.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getCountryNameWithCode } from '../../common/helpers/CountryCodeHelper.js';
import { translateKey } from '../../common/helpers/Common';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


class ArticleListTable extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            snackBarOpen: false,
            downloadErrorSnackBar: false,
            deleteRecordTosterMessage: '',
            downloadErrorSnackBarMessage: ''
        }
        this.deleteApassRequest = this.deleteApassRequest.bind(this);
        this.downloadApassExport = this.downloadApassExport.bind(this);
    }

    deleteApassRequest(apassRequestId, index) {
        this.props.displayDeleteButton(index, false)
        this.context.apiService
            .deleteApassRequest(apassRequestId)
            .then(res => {
                if (res.status === 200) {
                    res.json().then(res => {
                        this.setState({snackBarOpen: true});
                        this.setState({deleteRecordTosterMessage: `${apassRequestId} - Deleted successfully`});
                        this.props.updateStatus(index, 'deleted')
                    });
                } else {
                    res.json().then(res => {
                        console.log("Error in fetching article pass request");
                        console.log(res);
                    })
                }
            });
    }

    downloadApassExport(apassExportId, filename, index, gtin) {
        const errorMessage = `Error while downloading GTIN: ${gtin}`
        const file_name = filename.split('/').pop()

        this.props.displayDownloadButton(index, false)
        this.context.apiService
            .downloadApassExport(apassExportId)
            .then(res => {
                if (res.ok) {
                   return res.blob()
                } else {
                    this.props.displayDownloadButton(index, true)

                    this.setState({downloadErrorSnackBarMessage: errorMessage});
                    this.setState({downloadErrorSnackBar: true})
                    res.json().then(res => {
                        console.log("Error in fetching article pass request");
                        console.log(res);
                    })
                }
            }).then(blob => {
                if (blob !== undefined) {
                    if (blob instanceof Blob) {
                        saveAs(blob, file_name);
                        this.props.displayDownloadButton(index, true)
                    } else {
                        this.props.displayDownloadButton(index, true)

                        this.setState({downloadErrorSnackBarMessage: errorMessage});
                        this.setState({downloadErrorSnackBar: true})
                        console.log("Not a valid content");
                    }
                }
            });
    }

    handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({snackBarOpen: false})
        this.setState({downloadErrorSnackBar: false})
    };

    renderNullText() {
        return <i>null</i>;
    }

    renderStatus(status) {
        const prefix = 'markantDataRequestModal'
        
        switch (status) {
            case 'deleted':
              return translateKey('deleted', prefix)
            case 'found':
               return translateKey('found', prefix)
            case 'init':
               return translateKey('init', prefix)
            case 'not_a_base_unit':
               return translateKey('notABaseUnit', prefix) 
            default:
              return status
          }

    }

    render() {
        const { t } = this.props;
        const language = getCurrentLanguage(process.env.REACT_APP_SOURCE)

        return <Paper elevation={2}>
            <Snackbar open={this.state.snackBarOpen} autoHideDuration={6000} onClose={this.handleTosterClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
                <Alert onClose={this.handleTosterClose} severity="success"
                    action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={this.handleSnackBarClose}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>}>
                    {this.state.deleteRecordTosterMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={this.state.downloadErrorSnackBar} autoHideDuration={6000} onClose={this.handleTosterClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
                <Alert onClose={this.handleTosterClose} severity="error"
                    action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={this.handleSnackBarClose}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>}>
                    {this.state.downloadErrorSnackBarMessage}
                </Alert>
            </Snackbar>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="left" style={{fontWeight: 'bold'}}>{t('markantDataRequestModal.gln')}</TableCell>
                        <TableCell align="center" style={{fontWeight: 'bold'}}>{t('markantDataRequestModal.ekg')}</TableCell>
                        <TableCell align="center" style={{fontWeight: 'bold'}}>{t('markantDataRequestModal.targetMarket')}</TableCell>
                        <TableCell align="center" style={{fontWeight: 'bold'}}>{t('markantDataRequestModal.gtin')}</TableCell>
                        <TableCell align="center" style={{fontWeight: 'bold'}}>{t('markantDataRequestModal.status')}</TableCell>
                        {this.props.tableType == 'apassExportList' && <TableCell align="center" style={{fontWeight: 'bold'}}>{t('markantDataRequestModal.as2StatusHeading')}</TableCell> }
                        <TableCell align="center" style={{fontWeight: 'bold'}}>{t('markantDataRequestModal.createdAt')}</TableCell>
                        <TableCell align="center" style={{fontWeight: 'bold'}}>{t('markantDataRequestModal.updatedAt')}</TableCell>
                        <TableCell align="center" style={{fontWeight: 'bold'}}>{t('markantDataRequestModal.actions')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { this.props.areRecordsLoaded ? (
                        this.props.records.map((item, index) => (
                            <TableRow key={item.id}>
                                <TableCell component="th" scope="row">{item.gln || this.renderNullText()}</TableCell>
                                <TableCell align="center">{item.ekg || this.renderNullText()}</TableCell>
                                <TableCell align="center">{getCountryNameWithCode(item.targetMarket, language) || this.renderNullText()}</TableCell>
                                <TableCell align="center">{item.gtin || this.renderNullText()}</TableCell>
                                <TableCell align="center">{this.renderStatus(item.status) || this.renderNullText()}</TableCell>
                                {this.props.tableType == 'apassExportList' && <TableCell align="center">{t("markantDataRequestModal.as2Status."+(item.as2Status)) || this.renderNullText()}</TableCell>}
                                <TableCell align="center">{new Date(item.createdAt).toLocaleString()}</TableCell>
                                <TableCell align="center">{new Date(item.updatedAt).toLocaleString()}</TableCell>
                                <TableCell align="center">
                                    { this.props.tableType == 'apassRequest'? (
                                        item.status !== 'deleted' && (
                                            ( item.showDeleteButton ?
                                                <Tooltip title="Delete">
                                                    <IconButton onClick={() => this.deleteApassRequest(item.id, index)}>
                                                        <DeleteIcon style={{ color: red[500], cursor: 'pointer' }} />
                                                    </IconButton>
                                                </Tooltip> :
                                                <Tooltip title="Delete">
                                                    <IconButton>
                                                        <CircularProgress size={20} style={{ color: palette.secondary.main }} thickness={4} />
                                                    </IconButton>
                                                </Tooltip>
                                            )
                                        ) )
                                        :
                                        ( item.showDownloadButton ?
                                            ( item.filename && item.filename.includes('.xlsm') &&
                                            <Tooltip title="Download">
                                                <IconButton color="primary" onClick={() => this.downloadApassExport(item.id, item.filename, index, item.gtin)}>
                                                    <GetAppIcon color="primary" style={{cursor: "pointer", marginRight: '8%'}}/>
                                                </IconButton>
                                            </Tooltip> ):
                                            <Tooltip title="Download">
                                                <IconButton>
                                                    <CircularProgress size={20} style={{ color: palette.secondary.main }} thickness={4} />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }                                      
                                </TableCell>
                            </TableRow>
                        )))
                    :
                    (<TableRow>
                        <TableCell colSpan={8}>
                            <div style={{textAlign: 'center'}}>
                                <CircularProgress size={40} style={{ color: palette.secondary.main }} align="center" /> 
                            </div>
                        </TableCell>
                    </TableRow>)}

                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={this.props.total}
                rowsPerPage={this.props.rowsPerPage}
                page={this.props.page}
                onPageChange={this.props.onPageChange}
                onRowsPerPageChange={this.props.onRowsPerPageChange}
            />
        </Paper>

    }
}

export default withTranslation()(ArticleListTable);