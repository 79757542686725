
import React from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import { isEmailValid } from '../../../common/helpers/Validator';
import { CustomerContext } from "../../../common/CustomerContext.js";
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class InviteWinemaker extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            winemakerAddress: '',
            winemakerName: '',
            isSubmitting: false,
            isSubmitted: false,
            isSubmissionSuccessful: false
        };
        this.submitButtonOnClick = this.submitButtonOnClick.bind(this);
        this.handleWinemakerAddress = this.handleWinemakerAddress.bind(this);
        this.handleWinmakerName = this.handleWinmakerName.bind(this);


    }

    handleWinemakerAddress(event) {
        this.setState({
            winemakerAddress: event.target.value
        })
    }

    handleWinmakerName(event) {
        this.setState({
            winemakerName: event.target.value
        })
    }

    shouldSubmitButtonBeEnabled() {
        return (isEmailValid(this.state.winemakerAddress)
        && (this.state.winemakerAddress.length !== 0)
          );
    }

    submitButtonOnClick() {
        console.log("submit button on click");
        const clientId = this.props.clientId;
        this.setState({
            isSubmitting: true,
            isSubmitted: false,
        })


        this.context.apiService.inviteWinemaker(this.state.winemakerAddress,this.state.winemakerName, clientId)
            .then(res => {
                console.log("Euvino Response");
                console.log(res);
                this.setState({
                    responseStatus: res.status,
                    });
                if (res.status===200) {
                        this.setState({
                        isSubmitting: false,
                        isSubmissionSuccessful: true,
                        isSubmitted: true,
                        });
                } else {
                        console.log(res);
                        console.log(res.errors)
                        this.setState({
                            isSubmissionSuccessful: false,
                            isSubmitting: false,
                            isSubmitted: true,
                        });
                }
            });
    }


    render() {
        const { t } = this.props;

        return <div>
            <Accordion elevation={1}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography><b>{t('euvinoModal.inviteWinemaker')}</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <br />
                    <Grid container direction="row" justify="flex-start" spacing={3}>
                        <Grid item>
                            <TextField
                                label={t('euvinoModal.winemakerAddress')}
                                id="winemakerAddress"
                                value={this.state.winemakerAddress}
                                error={!isEmailValid(this.state.winemakerAddress)}
                                helperText={isEmailValid(this.state.winemakerAddress) ? '' : t('euvinoModal.emailValidationText')}
                                onChange={this.handleWinemakerAddress} variant="outlined" size="small"
                                style={{ width: '300px', alignItems: "left",}}
                                disabled={this.state.isSubmitting}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label={t('euvinoModal.winmakerName')}
                                id="winmakerName"
                                value={this.state.winemakerName}
                                onChange={this.handleWinmakerName} variant="outlined" size="small"
                                style={{ width: '300px' }}
                                disabled={this.state.isSubmitting}
                            />
                        </Grid>

                        <Grid item>
                            <Button variant="contained"
                                color="primary"
                                disabled={!this.shouldSubmitButtonBeEnabled()}
                                onClick={this.submitButtonOnClick}
                                style={{ height: '40px' }}
                            >
                                {t('euvinoModal.invitation')}
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <div><br />
                                {this.state.isSubmitting && <div><br /><LinearProgress /></div>}
                                {this.state.isSubmitted ?
                                    !this.state.isSubmissionSuccessful ?
                                         <Alert severity="error">{t('euvinoModal.newInviteWinemakerErrorMessage')}</Alert>
                                        : <Alert severity="success">{t('euvinoModal.newInviteWinemakerSuccessMessage')}</Alert>
                                    : null
                                }
                            </div>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>;
    }
}
export default withTranslation()(InviteWinemaker);
