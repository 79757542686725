import React from "react";

import { withTranslation } from 'react-i18next';
import {palette} from '../assets/Palette';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';

class ExcelFileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            showProgress: false 
        }
        this.handleFileUpload = this.handleFileUpload.bind(this);
    }

    handleFileChange = (event) => {
        console.log(event.target.files[0])
        this.setState({file: event.target.files[0]}, () => {
            this.handleFileUpload()
        })
    };

    handleFileUpload(event) {
        if (!this.state.file) return;
        
        const formData = new FormData();
        formData.append('file', this.state.file);
    
        try {
            this.setState({showProgress: true})
            this.props.apiService.excelFileUpload(formData)
            .then(res => res.json())
            .then(res => {
                if (!('error' in res)) {
                    this.props.updateItemDetailsState(res.records)
                    this.props.updateRequesterEmailChange(res.replyAddress)
                    this.props.updateCountryCodeChange(res.countryCode)
                    this.props.updateSupplierMasterData(res.supplierMasterData)
                } else {
                    this.props.updateExcelUploadErrorState(true)
                }
                this.setState({showProgress: false})
            }).catch(err => console.error(err));
        } catch (error) {
          console.error('Error uploading file:', error);
        }
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                { !this.state.showProgress ? 
                <>
                    <input accept=".xlsx, .xls" style={{display: 'none'}} id="contained-button-file" type="file" onChange={this.handleFileChange}/>
                    <label htmlFor="contained-button-file">
                        <Tooltip title={t('markantDataRequestModal.uploadExcel')} aria-label={t('markantDataRequestModal.uploadExcel')}>
                            <IconButton color="primary" aria-label="upload picture" component="span">
                                <CloudUploadIcon />
                            </IconButton>
                        </Tooltip>
                    </label>
                </> : <IconButton color="primary" component="span" style={{paddingTop:"15px"}}>
                        <CircularProgress size={20} style={{ color: palette.secondary.main }} thickness={4} />
                    </IconButton>
                }
            </div>
          );
    }
}

export default withTranslation()(ExcelFileUpload);