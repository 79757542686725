import React from "react";
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import {palette} from '../assets/Palette';
import {getBaseUrl} from '../common/helpers/Common'

class HelpEn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        document.body.classList.add("white-content");
    }

    generateDocumentation() {
        let doc = []
        let baseUrl = getBaseUrl()
        let docUrl = baseUrl + '/schwarz-docs/'

        doc.push(<h3 key='h3'>Help on search API</h3>)
        
        doc.push(
          <a key="doc" href={docUrl} target="_blank" rel="noopener noreferrer"> 
            Techinical documentation search-API
          </a>
        );
        return doc;
    }

    render() {
        return (
            <>
                <div className="content">
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0.6%' }}>
                                    <img src={require('../assets/img/BYRD_ContentHub_horizontal_color_RGB.png')} alt="logo" width='192' height='53' />
                                </div>
                            </CardHeader>
                            <CardBody style={{ marginTop: '2%' }}>
                                <div style={{ fontSize: '1rem' }}>
                                    <h2 style={{ color: palette.text.dark }}><b>Hilfe zur Artikelsuche im Produkt Data Pool </b></h2>
                                    <h3 style={{ color: palette.text.dark }}><b>Allgemeine Informationen</b></h3>
                                    <h4 style={{ color: palette.text.dark }}><b>Was kann gesucht werden?</b></h4>

                                    <ul style={{ borderLeft: '0px' }}>
                                        <li style={{ color: palette.text.main }}>Preis- und Listungsdaten aus SAP</li>
                                        <li style={{ color: palette.text.main }}>Stammdaten von Artikeln aus PIM</li>
                                        <li style={{ color: palette.text.main }}>Publizierte Stammdaten aus dem GDSN</li>
                                    </ul>

                                    <h4 style={{ color: palette.text.dark }}><b>Wie kann gesucht werden?</b></h4>

                                    <p style={{ color: palette.text.main }}>
                                        Die Suche wird durch Eingabe eines einzelnen Suchbegriffs oder mehrerer, durch Leerzeichen getrennter Begriffe im Suchfeld getriggert. Die Suche aktualisiert sich dabei alle paar Sekunden oder bei Änderungen im Filter. Bitte beachten Sie das die vorhergegangene Suchanfrage immer erst fertig geladen sein muss, bevor weitere folgen können.<br/><br/>
                                        Die Suche berücksichtig dabei alle im Suchfeld enthalten Begriffe und durchsucht alle Attribute der verfügbaren Artikel.<br/><br/>
                                        Das heißt es kann vorkommen, dass Artikel in der Trefferliste aufgelistet werden, der Suchbegriff dort aber nicht direkt ersichtlich ist.<br/><br/>
                                        Dadurch kann neben den eigentlich Artikelnamen und Herstellernamen auch nach zum Beispiel Inhaltsstoffen gesucht werden.<br/><br/>
                                        
                                        Neben der Eingabe eines klassischen Suchbegriffs hängt die Suche auch von den Filtereinstellungen darunter ab. Die effizienteste Nutzung der Suche sieht dabei wie folgt aus:<br/><br/> 

                                        <ol style={{ borderLeft: '0px' }}>
                                            <li style={{ color: palette.text.main }}><b>Suchfilter einstellen</b>, z.B. exakter Begriff oder GTIN im Anschluss PIM und Markant als Datenquelle auswählen. Und nur nach Basisartikeln suchen.</li>
                                            <li style={{ color: palette.text.main }}>Eine weitere Option ist die Nutzung der <b>spezifischen Suche</b>, da hierbei der zu durchsuchender Bereich eingegrenzt wird und die Suche deshalb schneller erfolgt.</li>
                                            <li style={{ color: palette.text.main }}>Ist das Ergebnis nach Anwendung von Schritt 1 und 2 noch nicht spezifisch genug haben Sie im Anschluss dann noch die Möglichkeit die <b>Ergebnisliste zu filtern.</b></li>
                                        </ol>

                                        Beachten Sie, dass je spezifischer ihre Anfrage, desto besser auch die Ergebnisse.<br/><br/>
                                    </p>
                                    
                                    <h3 style={{ color: palette.text.dark }}><b>Suchfilter einstellen</b></h3>
                                    <h4 style={{ color: palette.text.dark }}><b>Für die Suche können verschiedene Einstellungen gemacht werden:</b></h4><br/>

                                    <ul style={{ borderLeft: '0px' }}>
                                        <li style={{ color: palette.text.main }}><b>Artikel pro Seite</b><br/>Gibt an, wie viele Einträge in der Suchtrefferliste angezeigt werden. Je mehr Einträge gewählt werden, umso mehr Zeit wird ggfs. benötigt, um das Ergebnis anzuzeigen.</li><br />
                                        <li style={{ color: palette.text.main }}><b>Logische Verknüpfung</b><br/><b>Und - </b>die eingegebenen Suchbegriffe müssen ALLE in einem Artikel gefunden werden. Die Eingabe der Suchbegriffe “Fisch Fleisch” mit dieser Option findet nur Artikel, die den Begriff “Fisch” UND den Begriff “Fleisch” in beschreibenden Attributen enthalten.<br/>
                                            <b>Oder - </b>mindestens einer der Suchbegriffe muss einem Artikel gefunden werden. Die Eingabe der Suchbegriffe “Fisch Fleisch” mit dieser Option findet Artikel, die entweder den Begriff “Fisch” ODER den Begriff “Fleisch” ODER beide Begriffe in beschreibenden Attributen enthalten.</li><br/>
                                        <li style={{ color: palette.text.main }}><b>Suche nach exaktem Begriff</b><br/>Ist diese Option aktiv, werden die Suchbegriffe nur in der exakten Schreibweise gefunden, ansonsten werden auch unvollständige Schreibweisen gefunden.<br/>Beispiel: Die Eingabe des Suchbegriffs “012345” findet ohne diese aktive Option auch Artikel mit der Schreibweise “0123456” oder “123012345”.</li><br/>
                                        <li style={{ color: palette.text.main }}><b>Suchen in</b><br/>Einschränkung der zu durchsuchende Datenmenge auf einen bestimmten angebundenen Datenlieferanten.</li><br/>
                                        <li style={{ color: palette.text.main }}><b>Zielmarkt</b><br/>Sie können auch den Zielmarkt einschränken</li><br/>
                                        <li style={{ color: palette.text.main }}><b>Suchumfang</b><br />Die Datenmenge beinhaltet nicht nur Basisartikel, sondern auch Verpackungen, wie z.B. Kartons oder Paletten. Mit der aktiven Option “Nur Basisartikel anzeigen” werden die Verpackungen aus dem Suchergebnis ausgeschlossen</li>
                                        
                                    
                                    </ul><br/>
                                    
                                    <h3 style={{ color: palette.text.dark }}><b>Spezifische Suche</b></h3>
                                    <h4 style={{ color: palette.text.dark }}><b>Verwendung von Attributbezeichnern als Suchfilter</b></h4>

                                    <p style={{ color: palette.text.main }}>
                                        Mit Hilfe von Attributbezeichnern kann auch nach spezifischen Attributen gesucht werden. Der Attributbezeichner wird als Präfix mit Doppelpunkt getrennt dem Suchbegriff vorangestellt. Auf die exakte Schreibweise (Groß-/Kleinschreibung) des Attributbezeichners ist zu achten.<br/><br/>
                                        Beispiel: brandName:Suchbegriff beschränkt die Suche von „Suchbegriff“ auf das Attribut „Markenname“, z.B. “brandName:Fanta” findet nur Artikel, die den Begriff “Fanta” im Markennamen enthalten.
                                        <br/><br/>
                                    </p>
                                                                        
                                    <h4 style={{ color: palette.text.dark }}><b>Liste der möglichen Suchfilter:</b></h4>

                                    <ul style={{ borderLeft: '0px' }}>
                                        <li style={{ color: palette.text.main }}><b>source:</b> (Datenquelle, im Moment nur “GDSN” möglich)</li>
                                        <li style={{ color: palette.text.main }}><b>productName:</b> (Artikelbezeichnung)</li>
                                        <li style={{ color: palette.text.main }}><b>productDescription:</b> (Artikelbeschreibung)</li>
                                        <li style={{ color: palette.text.main }}><b>brandName:</b> (Markenname)</li>
                                        <li style={{ color: palette.text.main }}><b>manufacturer:</b> (Hersteller/Lieferant)</li>
                                        <li style={{ color: palette.text.main }}><b>targetMarket:</b> (Zielmarkt)</li>
                                        <li style={{ color: palette.text.main }}><b>gtin:</b> (GTIN eines Artikels)</li>
                                        <li style={{ color: palette.text.main }}><b>gln:</b> (GLN eines Herstellers)</li>
                                        <li style={{ color: palette.text.main }}><b>gpc:</b> (GPC Code Baustein, Klassem Familie oder Segment)</li>
                                    </ul>
                                    
                                    <h4 style={{ color: palette.text.dark }}><b>Ergebnisliste filtern</b></h4>

                                    <p style={{ color: palette.text.main }}>
                                        Als weitere Option besteht die Möglichkeit die Ergebnisliste zu filtern. Hier kann nach Herstellern und nach GPCs gefiltert werden.<br/><br/> 
                                        Hierbei gibt es jedoch einige Besonderheiten.<br/><br/>
                                        Haben Sie eine noch zu große Trefferliste, muss aus Darstellungsgründen die Auswahl der Hersteller und GPCs auf 14 begrenzt werden.<br/><br/>  
                                        Eine kleine Eigenart des Produkt Data Pools ist, dass wenn sie mehr als 14 GPCs oder Hersteller angezeigt bekommen und sie einen von den GPCs oder Herstellen abwählen, auch alle weiteren nicht angezeigten GPCs und Hersteller nicht mehr ausgewählt sind. Wollen Sie das zurücksetzten müssen sie den Schalter vorne einmal nach links und wieder nach rechts stellen.<br/><br/>
                                        Die Verbindung zwischen GPC und Hersteller ist eine „und“-Verbindung, das bedeutet, dass wenn die GPC 1 und Hersteller 1 ausgewählt haben, dann werden ihnen nur Artikel von Hersteller 1 angezeigt, die die GPC 1 haben.<br/><br/>
                                        Alle Artikel bei denen keine GPC gepflegt ist aufgrund fehlender Datenqualität in den Quellsystemen haben die GPC 99999999999.<br/><br/>
                                        Beachten Sie bitte auch, dass, wenn sie nach einzelnen Herstellern oder GPCs suchen und diese Attribute bei dem von ihrem gewünschten Artikel nicht gepflegt sind, kann dieser Artikel aufgrund der „und“ Verbindung nicht angezeigt werden.<br/><br/>
                                        Achtung: TIF-Bildformat kann in vielen Browsern nicht angezeigt werden.<br/>

                                        <br/>{this.generateDocumentation()}
                                    </p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            </>
        );
    }
}
export default withRouter(HelpEn)
