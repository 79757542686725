
import React from "react";
import SearchFilter from "./SearchFilter.jsx";
import { withTranslation } from 'react-i18next';
import ArticleListTable from "./ArticleListTable.jsx";
import { CustomerContext } from "../../common/CustomerContext.js";
import{ getCurrentLanguage, getNumCountryCode, getCookie} from '../../common/helpers/Common.js';

class ArticlePassExportList extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            total: 0,
            rowsPerPage: 10,
            page: 0, // zero-based
            targetMarketSearch: '',
            gtinSearch: '',
            glnSearch: '',
            ekgSearch: '',
            validTargetMarkets: [],
            filterChange: false,
            isSearching: false,
            areRecordsLoaded: false
        }

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleEkgSearch = this.handleEkgSearch.bind(this);
        this.handleGlnSearch = this.handleGlnSearch.bind(this);
        this.handleGtinSearch = this.handleGtinSearch.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.displayDownloadButton = this.displayDownloadButton.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleTargetMarketSearch = this.handleTargetMarketSearch.bind(this);
        this.initArticlePassExportList = this.initArticlePassExportList.bind(this);
        this.getArticlePassExportListWithFilter = this.getArticlePassExportListWithFilter.bind(this)
    }
    
    componentDidMount() {
        this.getValidTargetMarkets();
        this.initArticlePassExportList();
    }

    initArticlePassExportList() {
        let countryCode = getCookie('country_code')
        countryCode = getNumCountryCode(countryCode)

        if ( countryCode !== "" ) {
            this.setState( { targetMarketSearch: countryCode }, 
                () => this.getArticlePassExportList(true)
            )
        } else {
            this.getArticlePassExportList()
        }
        
    }

    getArticlePassExportList(useFilters = false) {
        let ekg = this.state.ekgSearch
        let gtin = this.state.gtinSearch
        let targetMarket = (this.state.targetMarketSearch !== 'all') ? this.state.targetMarketSearch : ''
        let gln = this.state.glnSearch

        this.setState({ areRecordsLoaded: false });
        this.context.apiService
            .getArticlePassExportList(useFilters, ekg, gtin, targetMarket, gln, this.state.page + 1, this.state.rowsPerPage)
            .then(res => {
                if (res.ok) {
                    res.json().then(res => {
                        const newResponseRecords = res.data.map(record => ({
                            ...record, 
                            showDownloadButton: true,
                            showLoader: false
                        }))

                        this.setState({ data: newResponseRecords, total: res.total, isSearching: false });
                        this.setState({ areRecordsLoaded: true });
                    });
                } else {
                    res.json().then(res => {
                        console.log("Error in fetching article pass export list");
                        console.log(res);
                        this.setState({ isSearching: false });
                        this.setState({ areRecordsLoaded: false });
                    })
                }
            });
    }

    getValidTargetMarkets() {
        this.context.apiService.getValidTargetMarketsForMarkant()
            .then(res => {
                if (res.ok) {
                    res.json().then(res => {
                        this.setState({ validTargetMarkets: res.data });
                    });
                } else {
                    res.json().then(res => {
                        console.log(res);
                    })
                }
            });
    }

    getArticlePassExportListWithFilter() {
        this.setState({
            page: 0
        }, () => this.getArticlePassExportList(true));
    }

    handleOnChange(event) {
        let fieldId = event.target.id;
        let value = event.target.value;
        this.setState({
            [fieldId]: value
        });
    }

    handleChangeRowsPerPage(event) {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        }, () => this.getArticlePassExportList(true));
    }

    handleChangePage(event, newPage) {
        this.setState({
            page: newPage
        }, () => this.getArticlePassExportList(true));
    }

    handleTargetMarketSearch(targetMarket) {
        this.setState({ targetMarketSearch: targetMarket })
    }

    handleEkgSearch(ekg) {
        this.setState({ ekgSearch: ekg })
    }

    handleGtinSearch(gtin) {
        this.setState({ gtinSearch: gtin })
    }

    handleGlnSearch(gln) {
        this.setState({ glnSearch: gln })
    }

    displayDownloadButton(recordIndex, status){
        this.setState({
            data: this.state.data.map((record, index) =>
              recordIndex === index ? { ...record, showDownloadButton: status } : record
            )
        });
    }


    render() {
        const { t } = this.props;
        const language = getCurrentLanguage(process.env.REACT_APP_SOURCE)

        return <div>
            <SearchFilter isSearching = {this.state.isSearching} 
                handleTargetMarketSearch = {this.handleTargetMarketSearch} 
                handleEkgSearch = {this.handleEkgSearch} 
                handleGtinSearch = {this.handleGtinSearch} 
                handleGlnSearch = {this.handleGlnSearch} 
                targetMarketSearch = {this.state.targetMarketSearch} 
                gtinSearch = {this.state.gtinSearch} 
                glnSearch = {this.state.glnSearch} 
                ekgSearch = {this.state.ekgSearch} 
                validTargetMarkets = {this.state.validTargetMarkets}  
                submitFunction = {this.getArticlePassExportListWithFilter} 
                handleOnChange = {this.handleOnChange}
            />
            <div style={{ paddingTop: '2em' }}>
                <ArticleListTable 
                    records={this.state.data}
                    total={this.state.total} 
                    rowsPerPage={this.state.rowsPerPage} 
                    page={this.state.page} 
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    tableType='apassExportList'
                    displayDownloadButton={this.displayDownloadButton}
                    areRecordsLoaded = {this.state.areRecordsLoaded}
                />
            </div>
        </div>
    }
}
  
export default withTranslation()(ArticlePassExportList);