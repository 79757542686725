import React from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import { isSearchTermValid} from '../../../common/helpers/Validator'
import { CustomerContext } from "../../../common/CustomerContext.js";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddProducerPopup from './AddProducerPopup';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';



class SearchWinemaker extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            winemakers:[],
            isSubmitting: false,
            isSubmitted: false,
            isSubmissionSuccessful: false,
            showComponent: false,
            closePopup: false,
            clientId: this.props.clientId
        };
        this.submitButtonOnClick = this.submitButtonOnClick.bind(this);
        this.closeOnClick = this.closeOnClick.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentWillReceiveProps(newProps) {
        if (newProps.isClientChanged) {
            this.setState({
                showComponent: !this.state.showComponent,
                searchTerm: '',
                winemakers: []
            });
        }
    }

    shouldSubmitButtonBeEnabled() {
        return (isSearchTermValid(this.state.searchTerm)
           );
    }

    handleChange(event) {
        this.setState({
            searchTerm: event.target.value
        })
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          this.submitButtonOnClick();
        }
      };

    closeOnClick() {
        console.log("Close button on click");
        this.setState({
            isSubmitting: false,
            isSubmitted: false,
            isSubmissionSuccessful: false,
            showComponent: !this.state.showComponent
        });

    }

    submitButtonOnClick() {
        console.log("submit button on click");
        this.setState({
            isSubmitting: true,
            isSubmitted: false,
            showComponent: true
        });
        this.getSearchProducer();
    }

    getSearchProducer(){
        const clientId = this.props.clientId;

        this.context.apiService.getSearchProducer(this.state.searchTerm, clientId)
            .then(res => {
                console.log("Euvino Response");
                console.log(res);
                if (res.ok) {
                    res.json().then(res => {
                        this.setState({
                        winemakers: res.preview!=null?res.preview:res,
                        isSubmitting: false,
                        isSubmissionSuccessful: true,
                        isSubmitted: true,
                        });

                });
                } else {
                        console.log(res);
                        console.log(res.errors)
                        this.setState({
                            isSubmissionSuccessful: false,
                            isSubmitting: false,
                            isSubmitted: true,
                            showComponent: false
                    });

                }
            });
    }


    render() {
        const { t } = this.props;

        return <div>
            <Accordion elevation={1}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                <Typography><b>{t('euvinoModal.searchProducer')}</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <br />
                    <Grid container direction="row" justify="flex-start" spacing={1}>
                        <Grid item>
                            <TextField
                                label={t('euvinoModal.searchTerm')}
                                id="searchTerm"
                                value={this.state.searchTerm}
                                error={(this.state.searchTerm.length !== 0) && (!isSearchTermValid(this.state.searchTerm))}
                                helperText={((this.state.searchTerm.length === 0) || (isSearchTermValid(this.state.searchTerm))) ? ' ' : t('euvinoModal.validationText')}
                                onChange={this.handleChange} variant="outlined" size="small"
                                onKeyPress={this.handleKeyPress}
                                style={{ width: '410px' }}
                                disabled={this.state.isSubmitting}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained"
                                color="primary"
                                disabled={!this.shouldSubmitButtonBeEnabled()}
                                onClick={this.submitButtonOnClick}
                                style={{ height: '40px' }}
                            >
                                {t('euvinoModal.search')}
                            </Button>
                        </Grid>
                        <Grid item>
                            { this.state.showComponent ?
                                 <AddProducerPopup
                                    closePopup={this.closeOnClick}
                                    wineMakerlist={this.state.winemakers}
                                    isSuccess={this.state.isSubmissionSuccessful}
                                    refreshSearchProducers={()=>this.getSearchProducer()}
                                    refreshKnownProducers={()=>this.props.refreshKnownProducers()}
                                    clientId={this.props.clientId}
                                />
                                : null
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <div><br />
                                {this.state.isSubmitting && <div><br /><LinearProgress /></div>}
                                {this.state.isSubmitted ?
                                    !this.state.isSubmissionSuccessful ?
                                         <Alert severity="error">{t('euvinoModal.newSearchWinemakerErrorMessage')}</Alert>
                                        : null
                                    : null
                                }
                            </div>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>;
    }

}
export default withTranslation()(SearchWinemaker);
