
import React from "react";
import Modal from 'react-modal';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { CustomerContext } from "../../common/CustomerContext.js";
import { withTranslation } from 'react-i18next';
import SearchWinemaker from "./components/SearchWinemaker.jsx";
import InviteWinemaker from "./components/InviteWinemaker.jsx";
import KnownProducers from "./components/KnownProducers.jsx";
import Tooltip from '@material-ui/core/Tooltip';
import {palette} from '../../assets/Palette';
import EuvinoClientList from "../EuvinoClientList.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';


class EuvinoSubscriptionModal extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            clientId: (process.env.REACT_APP_SOURCE == 'radeberger') ? 'RG' : 'FP',
            isClientChanged: false,
            winemakerList: [],
            showLoadingIcon: false,
            disableClientList: false
        };

        this.getproducerList = this.getproducerList.bind(this);

    }

    componentDidUpdate(prevProps) {
        if (this.props.isEuvinoSubscriptionModalDisplayed !== prevProps.isEuvinoSubscriptionModalDisplayed) {
            this.getproducerList(this.state.clientId);
        }
    }

    onClientFilterChange = (event) => {
        this.setState({clientId: event.target.value}, () => {
            this.setState({isClientChanged: true})
            this.getproducerList(event.target.value)
        })
    }

    getproducerList(clientId) {
        this.setState({disableClientList: true})
        this.setState({showLoadingIcon: true})
        this.context.apiService.getProducerList(clientId)
            .then(res => {
                console.log("Euvino Response");
                if (res.ok) {
                    res.json().then(res => {
                        this.setState({showLoadingIcon: false})
                        this.setState({
                            winemakerList: res,
                        });
                        this.setState({disableClientList: false})
                    });
                } else {
                    console.log(res);
                    console.log(res.errors)
                }
            });
    }
    

    render() {

        return <Modal
            isOpen={this.props.isEuvinoSubscriptionModalDisplayed}
            toggle={this.props.toggleEuvinoSubscriptionModal}
            ariaHideApp={false}
        >   
            <Tooltip title={this.props.t('euvinoModal.close')}>
                <IconButton
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={this.props.toggleEuvinoSubscriptionModal}
                    style={{ outline: 'none' }}
                >
                    <CloseRoundedIcon style={{ color: palette.warning.close }} />
                </IconButton>
            </Tooltip>
            <h2>Euvino Subscription</h2>
            { process.env.REACT_APP_SOURCE == 'radeberger' &&
                <Grid container style={{paddingTop: "30px", paddingBottom: "20px"}}>
                    <Grid item xs={12} sm={12}>
                        <Grid item xs={2} sm={2} style={{ position: 'relative'}}>
                            <EuvinoClientList clientId={this.state.clientId} onFilterChange={this.onClientFilterChange} disableClientList={this.state.disableClientList} />
                        </Grid>
                    </Grid>
                </Grid>
            }
            <SearchWinemaker  refreshKnownProducers={()=>this.updateKnownProducers()} clientId={this.state.clientId} isClientChanged={this.state.isClientChanged} />
            <InviteWinemaker clientId={this.state.clientId}/>
            <KnownProducers winemakerList={this.state.winemakerList} showLoadingIcon={this.state.showLoadingIcon} clientId={this.state.clientId}/>
            <hr />
        </Modal>
    }
}

export default withTranslation()(EuvinoSubscriptionModal);
