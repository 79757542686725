import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { translateKey } from '../../common/helpers/Common';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import {palette} from '../../assets/Palette'
import Expandable from './ShowMoreLessModal';
import Divider from '@material-ui/core/Divider';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

class Article extends React.Component {
  renderSectionContent(data, sectionKey, prefix) {
    const headingStyle = {
      fontWeight: 'bold',
      fontSize: 'large',
      width: '35%'
    };
    
    return (
      data && Object.keys(data).length > 0 && (
        <React.Fragment>
          <tr>
            <td style={headingStyle}>
              {translateKey(sectionKey, prefix)}
            </td>
            <td key={'empty'}></td>
          </tr>
          {Object.keys(data).map((key) => {
            if (typeof data[key] !== 'string') {
              return (
                <tr key={'row' + key}>
                  <td>{translateKey(key, prefix)}</td>
                  <td>{data[key]}</td>
                </tr>
              );
            } else {
              let label_value = '';
              if (['website', 'recipe_website', 'product_website'].includes(key)) {
                let url = data[key];
                if (url && !url.startsWith('http')) {
                  url = 'https://' + url;
                }
                label_value = (
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    {translateKey('linkToWebsite', prefix)}
                  </a>
                );
              } else {
                label_value = data[key];
              }
              return (
                <tr key={'row' + key}>
                <td>{translateKey(key, prefix)}</td>  
                <td>
                  {label_value.length >= 500 && ['ingredientsList', 'marketingMessage'].includes(key) ? (
                    <Expandable maxChars={500}>
                      {label_value}
                    </Expandable>
                  ) : (
                    label_value
                  )}
                </td>
                </tr>
              );
            }
          })}
        </React.Fragment>
      )
    );
  }

  renderNutrient(sortedNutritionalValues, sectionKey, prefix) {
    const headingStyle = {
      fontWeight: 'bold',
      fontSize: 'large',
      width: '35%',
    };

    let headingKey;

    if (sortedNutritionalValues.length === 1) {
      // If there's only one element, set the headingKey accordingly
      const item = sortedNutritionalValues[0];
      if (item.nutrientBasisQuantityValue !== null) {
        headingKey = `${translateKey('nutritionalValuePer', prefix)} 
        ${item.nutrientBasisQuantityValue} 
        ${translateKey(item.nutrientBasisQuantityUnitCode, prefix)} - 
        ${translateKey(item.preparationStateCode, prefix)}`;
      } else {
        headingKey = `${translateKey('nutritionalValue', prefix)} - 
        ${translateKey(item.preparationStateCode, prefix)}`;
      }
    } else {
      // If there are multiple elements, set a default headingKey
      headingKey = translateKey('nutritionalValuePerBasisQuantity', prefix);
    }

    if (sortedNutritionalValues.length > 0) {
      // Display heading when there are items
      return (
        <React.Fragment>
          <tr>
            <td style={headingStyle}>
              {headingKey}
            </td>
            <td key={'empty'}></td>
          </tr>
          {/* Render the rest of the content with or without Accordion */}
          {sortedNutritionalValues.length === 1 ? (
            // If there's only one element, display it without an accordion
            <>
              {Object.entries(sortedNutritionalValues[0].nutrientDetail).map(([key, value]) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </>
          ) : (
            // If there are multiple elements, display them in accordions
            sortedNutritionalValues.map((item, index) => (
              <Accordion key={index} style={{ width: '285%' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={"panel" + index + "-content"} id={"panel" + index + "-header"}>
                <Typography style={{color: palette.text.ui_text}}>
                    {item.servingSizeDescription && (
                      <span>
                        {item.servingSizeDescription} - {' '}
                      </span>
                    )}
                    {translateKey('nutritionalValuePer', prefix)} {' '}
                    {item.nutrientBasisQuantityValue} {' '}
                    {translateKey(item.nutrientBasisQuantityUnitCode, prefix)} - {' '}
                    {translateKey(item.preparationStateCode, prefix)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table className='accordian_table'>
                      <TableBody>
                        {Object.entries(item.nutrientDetail).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCell style={{width: '34%', color: palette.text.ui_text}} >{key}</TableCell>
                            <TableCell style={{color: palette.text.ui_text}} >{value}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </React.Fragment>
      );
    } else {
      // Handle the case when there are no items
      return null; 
    }
  }
 
  renderCustomAttributes(data, sectionKey, prefix, level = 0, isTopLevelArray = false, isFirstChild = true) {
    const headingStyle = {
      fontWeight: 'bold',
      fontSize: 'large',
      width: '35%',
    };
  
    const isObject = (val) => val && typeof val === 'object';
  
    if (!data || Object.keys(data).length === 0) {
      return null;
    }
  
    const basePadding = 7; // base padding for the first line after heading
    const nestedPadding = 20; // padding increment for nested levels
  
    return (
      <React.Fragment>
        {level === 0 && !isTopLevelArray && (
          <tr>
            <td style={headingStyle}>
              {translateKey(sectionKey, prefix)}
            </td>
            <td></td>
          </tr>
        )}
  
        {Object.keys(data).map((key, index, array) => (
          <React.Fragment key={`${key}-${level}`}>
            {isObject(data[key]) || Array.isArray(data[key]) ? (
              <React.Fragment>
                {!Number.isInteger(Number(key)) && (
                  <tr>
                    <td style={{ paddingLeft: isFirstChild ? basePadding : level * nestedPadding, whiteSpace: 'nowrap' }}>
                      {`${translateKey(key, prefix)}`}
                    </td>
                    <td></td>
                  </tr>
                )}
                {this.renderCustomAttributes(data[key], sectionKey, prefix, level + 1, Array.isArray(data[key]), false)}
              </React.Fragment>
            ) : (
              <tr id={`${key}-${prefix}`}>
                {Number.isInteger(Number(key)) ? (
                  <>
                    <td style={{ paddingLeft: (level + 1) * nestedPadding, whiteSpace: 'nowrap' }} colSpan="2">{data[key]}</td>
                  </>
                ) : (
                  <>
                    <td style={{ paddingLeft: isFirstChild ? basePadding : level * nestedPadding, whiteSpace: 'nowrap' }}>
                      {translateKey(key, prefix)}
                    </td>
                    <td style={{ paddingLeft: 10 }}>{this.handleCustomAttributesValue(data[key])}</td>
                  </>
                )}
              </tr>
            )}
            {Array.isArray(data) && index < array.length - 1 && (
              <tr>
                <td colSpan="2" style={{ paddingLeft: level * nestedPadding, whiteSpace: 'nowrap' }}>
                  <Divider variant="inset" style={{ marginLeft: 20 }} />
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }
  
  handleCustomAttributesValue(value){
    if (value && typeof(value) === 'string' && value.startsWith('https://')) {
        return <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>
    } else {
      if (value === true) {
        value = "Yes"
      } else if (value === false) {
        value = "No"
      }
      return value
    }
  }

  render() {
    const { product } = this.props;
    
    const prefix = 'productDetailsModal';

    console.log(product)

    const articleInformation = product['articleInformation'];
    const marketing = product['marketing'];
    const ingredients = product['ingredients'];
    const allergens = product['allergens'];
    const nutritionalValuesPer100GramPerMilliliter = product['nutritionalValuesPer100GramPerMilliliter'];
    let sortedNutritionalValues = [];
    const articleDimensions = product['articleDimensions'];
    const articleCode = product['articleCode'];
    const moreItemInformation = product['moreItemInformation'];
    const customAttributes = this.props.customAttributes
    
    // Function to extract the first word
    const getFirstWord = str => {
      if (str && typeof str === 'string') {
        const words = str.trim().split(' ');
        return words.length > 0 ? words[0] : '';
      }
      return '';
    };

    // Sort the array based on servingSizeDescription in ascending order
    if (nutritionalValuesPer100GramPerMilliliter && nutritionalValuesPer100GramPerMilliliter.length > 0) {
      sortedNutritionalValues = nutritionalValuesPer100GramPerMilliliter.sort((a, b) => {
        // Extract the first word from servingSizeDescription
        const aFirstWord = getFirstWord(a.servingSizeDescription);
        const bFirstWord = getFirstWord(b.servingSizeDescription);
      
        // Compare based on the first word
        const servingSizeComparison = aFirstWord.localeCompare(bFirstWord);
      
        if (servingSizeComparison !== 0) {
          return servingSizeComparison;
        } else {
          // If the first words are the same, do the secondary sort on nutrientSortOrder (values 100 g / 100 ml must be on TOP)
          return a.nutrientSortOrder - b.nutrientSortOrder;
        }
      });
    } 

    return (
      <React.Fragment>
        {this.renderSectionContent(articleInformation, 'articleInformation', prefix)}
        {this.renderSectionContent(marketing, 'marketing', prefix)}
        {this.renderSectionContent(ingredients, 'ingredients', prefix)}
                
        {this.renderNutrient(sortedNutritionalValues, 'nutritional', prefix)}

        {this.renderSectionContent(allergens, 'allergens', prefix)}
        {this.renderSectionContent(articleDimensions, 'articleDimensions', prefix)}
        {this.renderSectionContent(articleCode, 'articleCode', prefix)}
        {this.renderSectionContent(moreItemInformation, 'moreItemInformation', prefix)}

        {this.renderCustomAttributes(customAttributes, 'customAttributesLabel', prefix)}

      </React.Fragment>       
    );
  }
}

export default Article;